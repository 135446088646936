import { RouteName } from "@/enums/RouteName";

export const quotePages = [
  "QuotesAdmin",
  "QuotesCustomer",
  "QuoteDetails",
  "QuoteList",
  RouteName.RequestForQuote,
];
export const customerPages = ["CustomerList", "CustomerDetailsHeader"];
export const userPages = [RouteName.Users];
export const layoutPages = ["TheMainLayout", "TheSecondaryLayout"];

export const allPagesWithoutLayout = [
  ...quotePages,
  ...customerPages,
  ...userPages,
];
export const allPages = [...allPagesWithoutLayout, ...layoutPages];
